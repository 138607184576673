import React from "react";
import logo from "./images/logo.png";
import people from "./images/people.png";
import peopleMobile from "./images/peopleMobile.png";
import camera from "./images/camera.png";
import building from "./images/building.png";
import phone from "./images/phone.png";
import partner from "./images/partner.png";
import person from "./images/person.png";
import qr from "./images/qr.png";
import "./App.css";

function App() {
  const [data, setData] = React.useState<{ [key: string]: string }>({
    subject: "From Landing Page, Join Us",
    firstName: "",
    lastName: "",
    province: "",
    phone: "",
    email: "",
  });

  const scroll = () => {
    const contactForm = document.getElementById("contactForm");
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="container">
      <div className="header">
        <img className="logo" src={logo} alt="logo" />
      </div>
      <div className="centerContainer">
        <div className="darkText title">At Nu Stream Realty</div>
        <div className="darkText title">Your Success is our Target!</div>
        <button className="button" onClick={scroll}>
          Join Us
        </button>
      </div>
      <div className="imageContainer">
        <img className="web" src={people} alt="people" />
        <img
          className="mobile"
          src={peopleMobile}
          alt="peopleMobile"
        />
      </div>
      <div className="darkContainer">
        <div className="centerContainer">
          <div className="lightText title">About NU STREAM Realty</div>
          <div className="lightText description">
            Nu Stream Realty Inc. ("Nu Stream" or the "Company") is a real
            estate company that was established in December 2015 in Vancouver.
            It expanded its operations to Toronto in 2016 and received strategic
            financing from reputable VP capital firms including IDG and Xiaomi.
            In October 2018, Nu Stream acquired Vanfun.com. The company launched
            JOHOME.com and the Johome mobile application, which have contributed
            to its current rapid growth.
          </div>
          <div className="row">
            <div className="lightText title width20">
              40<span className="subTitle">+</span>
              <div className="description">Outstanding Team Leaders</div>
            </div>
            <div className="divider"></div>
            <div className="lightText title width20">
              800<span className="subTitle">+</span>
              <div className="description">Exceptional real estate agents</div>
            </div>
            <div className="divider web"></div>
            <div className="lightText title width20">
              15000<span className="subTitle">+</span>
              <div className="description">Transactions closed</div>
            </div>
            <div className="divider"></div>
            <div className="lightText title width20">
              <span className="subTitle">$</span>
              16.8B
              <span className="subTitle">+</span>
              <div className="description">Transaction volume</div>
            </div>
          </div>
        </div>
      </div>
      <div className="centerContainer web">
        <div className="darkText title">FREE Ultimate Weapon to</div>
        <div className="darkText title">Help You Get Listings!</div>
      </div>
      <div className="centerContainer mobile">
        <div className="darkText title">
          FREE Ultimate Weapon to Help You Get Listings!
        </div>
      </div>
      <div className="centerContainer">
        <div className="row">
          <div className="column width60 mobile">
            <div className="darkText label">FREE Photography Service</div>
            <div className="darkText description">
              Dedicated Team to provide professional photography services
              completely FREE OF CHARGE.
            </div>
          </div>
          <div className="column width40">
            <img src={camera} alt="camera" />
          </div>
          <div className="column width60 web">
            <div className="darkText label">FREE Photography Service</div>
            <div className="darkText description">
              Dedicated Team to provide professional photography services
              completely FREE OF CHARGE.
            </div>
          </div>
        </div>
      </div>
      <div className="lightContainer">
        <div className="centerContainer">
          <div className="row">
            <div className="column width60">
              <div className="darkText label">Pre-Sale VIP Project</div>
              <div className="darkText description">
                Take advantage of working with a large organization, both in
                front of developers and clients, and it gives me a great sense
                of pride.
              </div>
            </div>
            <div className="column width40">
              <img src={building} alt="building" />
            </div>
          </div>
        </div>
      </div>
      <div className="centerContainer">
        <div className="row">
          <div className="column width60 mobile">
            <div className="darkText label">Johome Internet Platform</div>
            <div className="darkText description">
              It is incredible how technology has transformed my business and
              allowed me to make meaningful connections, ultimately helping
              people achieve their real estate goals.
            </div>
            <div>
              <button
                className="button"
                onClick={() => {
                  window.open("http://m.johome.com/download");
                }}
              >
                Download Johome
              </button>
            </div>
          </div>
          <div className="column width40">
            <img src={phone} alt="phone" />
          </div>
          <div className="column width60 web">
            <div className="darkText label">Johome Internet Platform</div>
            <div className="darkText description">
              It is incredible how technology has transformed my business and
              allowed me to make meaningful connections, ultimately helping
              people achieve their real estate goals.
            </div>
          </div>
        </div>
      </div>
      <div className="lightContainer">
        <div className="centerContainer">
          <div className="row">
            <div className="column width60">
              <div className="darkText label">
                Partner Plan | Consistent Earning
              </div>
              <div className="darkText description">
                With WIN-WIN solution, experienced realtors to voluntarily share
                knowledge and help newcomers grow rapidly in the industry.
              </div>
              <div>
                <button
                  className="button"
                  onClick={() => {
                    window.open("https://partnerplan.johome.com");
                  }}
                >
                  Learn More
                </button>
              </div>
            </div>
            <div className="column width40">
              <img src={partner} alt="partner" />
            </div>
          </div>
        </div>
      </div>
      <div className="darkContainer">
        <div className="textContainer">
          <div className="lightText title">Feel free to contact us at</div>
          <div className="lightText title">778 823 3666 or</div>
          <div className="lightText title">Click the Button Below</div>
          <button className="lightButton" onClick={scroll}>
            Contact Us
          </button>
        </div>
      </div>
      <div className="centerContainer">
        <div className="row">
          <div className="column width60 mobile">
            <div className="darkText centerLabel">Realtors' Stories :</div>
            <div className="darkText centerLabel">
              The Allure of JOHOME AR in Property Search
            </div>
            <div className="darkText description">
              During a property viewing, my client's desired apartment had
              already been sold. To ease their disappointment, I swiftly
              accessed the JOHOME app and used the AR distance feature to
              explore other available listings in the same building. This
              efficient and professional approach left a lasting impression on
              my clients.
            </div>
            <div className="centerLabel">
              <button
                className="button"
                onClick={() => {
                  window.open("https://eng.nustreamrealty.ca/testimonials.html");
                }}
              >
                TESTIMONIALS
              </button>
            </div>
          </div>
          <div className="column width40">
            <img src={person} alt="person" />
          </div>
          <div className="column width60 web">
            <div className="darkText label">Realtors' Stories :</div>
            <div className="darkText label">
              The Allure of JOHOME AR in Property Search
            </div>
            <div className="darkText description">
              During a property viewing, my client's desired apartment had
              already been sold. To ease their disappointment, I swiftly
              accessed the JOHOME app and used the AR distance feature to
              explore other available listings in the same building. This
              efficient and professional approach left a lasting impression on
              my clients.
            </div>
            <div>
              <button
                className="button"
                onClick={() => {
                  window.open("https://eng.nustreamrealty.ca/testimonials.html");
                }}
              >
                TESTIMONIALS
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="lightContainer">
        <div className="textContainer" id="contactForm">
          <div>&nbsp;</div>
          <div className="darkText label">Join Nu Stream</div>
        </div>
        <div className="formContainer">
          <div className="row">
            <div className="column width50">
              <div className="darkText description">Name*</div>
              <input
                className="input"
                type="text"
                placeholder="First Name"
                value={data.firstName}
                onChange={(e) => {
                  setData({ ...data, firstName: e.target.value });
                }}
              />
            </div>
            <div className="column width50">
              <div className="darkText description">&nbsp;</div>
              <input
                className="input"
                type="text"
                placeholder="Last Name"
                value={data.lastName}
                onChange={(e) => {
                  setData({ ...data, lastName: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="width30">
              <div className="darkText description">Select A Province*</div>
            </div>
            <div className="width30">
              <input
                type="radio"
                value="British Columbia"
                name="province"
                className="radio"
                checked={data.province === "British Columbia"}
                onChange={(e) => {
                  setData({ ...data, province: e.target.value });
                }}
              />
              British Columbia
            </div>
            <div className="width30">
              <input
                type="radio"
                value="Ontario"
                name="province"
                className="radio"
                checked={data.province === "Ontario"}
                onChange={(e) => {
                  setData({ ...data, province: e.target.value });
                }}
              />
              Ontario
            </div>
          </div>
          <div className="row">
            <div className="column width100">
              <div className="darkText description">Phone*</div>
              <input
                className="input"
                type="text"
                placeholder="ex. 778-123-1234"
                value={data.phone}
                onChange={(e) => {
                  setData({ ...data, phone: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="column width100">
              <div className="darkText description">Email</div>
              <input
                className="input"
                type="text"
                placeholder="ex. jack@gmail.com"
                value={data.email}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="textContainer">
              {data.firstName &&
                data.lastName &&
                data.province &&
                data.phone && (
                  <button
                    className="button"
                    onClick={() => {
                      const from =
                        data.province === "British Columbia"
                          ? "hr@johome.com"
                          : "hr@nustreamtoronto.com";

                      const to =
                        data.province === "British Columbia"
                          ? "hr@johome.com"
                          : "hr@nustreamtoronto.com";

                      const subject = data.subject;
                      const text = JSON.stringify(data);
                      let html =
                        '<table style="width:100%;border: 1px solid;border-collapse: collapse;">';

                      const formProperties = [
                        "subject",
                        "firstName",
                        "lastName",
                        "province",
                        "phone",
                        "email",
                      ];

                      for (const formProperty of formProperties) {
                        if (data[formProperty]) {
                          html =
                            html +
                            '<tr><td style="width:40%;border: 1px solid;border-collapse: collapse;">' +
                            formProperty +
                            '</td><td style="width:60%;border: 1px solid;border-collapse: collapse;">' +
                            data[formProperty] +
                            "</td></tr>";
                        }
                      }

                      html = html + "</table>";
                      fetch(`http://toronto.nustreamrealty.com/api/mail`, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                          from,
                          to,
                          subject,
                          text,
                          html,
                        }),
                      })
                        .then((res) => res.json())
                        .then((json) => {
                          if (json.message) {
                            alert(json.message);
                          } else {
                            alert("Can't send email, please try again later.");
                          }
                          setData({
                            subject: "From Landing Page, Join Us",
                            firstName: "",
                            lastName: "",
                            province: "",
                            phone: "",
                            email: "",
                          });
                        });
                    }}
                  >
                    Send
                  </button>
                )}
              {!(
                data.firstName &&
                data.lastName &&
                data.province &&
                data.phone
              ) && <button className="disabledButton">Send</button>}
            </div>
          </div>
          <div className="vdivider"></div>
          <div className="row">
            <div className="column width100">
              <div className="darkText description">Contact HR</div>
              <div className="description grayText">
                <div>Phone: +1 (778) 823 3666</div>
                <div>Email: hr@johome.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="row">
          <div className="width90">
            <h3>CONTACT US</h3>
            <div className="flexWraper">
              <div className="office">
                <h4>Burnaby</h4>
                #100 - 4555 Kingsway, Burnaby,
                <br />
                BC V5H 4T8
                <br />
                Office: 604 899 9999
              </div>
              <div className="office">
                <h4>Richmond</h4>
                #1550 - 4380 No.3 Rd, Richmond,
                <br />
                BC V6X 3V7
              </div>
              <div className="office">
                <h4>Toronto</h4>
                140 York Boulevard, Richmond Hill, <br />
                ON L4B 3J6
                <br />
                Office: 416 333 1111
                <br />
                Phone: 647 695 1188
              </div>
              <div className="office">
                <h4>Mississauga</h4>
                #400 - 30 Eglinton Avenue West, Mississauga,
                <br />
                ON L5R 3E7
              </div>
            </div>
          </div>
          <div className="width10">
            <div className="app web">
              <h3>DOWNLOAD APP</h3>
              <img src={qr} alt="qr" />
            </div>
          </div>
        </div>
        <div>
          Brokerages: British Columbia - NU STREAM REALTY INC., Ontario - NU
          STREAM REALTY (TORONTO) INC.
        </div>
        <div>© 2023 NU STREAM REALTY INC. ALL RIGHTS RESERVED.</div>
      </div>
    </div>
  );
}

export default App;
